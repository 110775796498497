// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/
import * as React from "react"
import styled from "styled-components"
import { useState } from "react"
import { useInView, motion } from "framer-motion"
import { fadeIn, draw, fadeInUp, staggerUp } from "./animation"

const Button = styled.button`
  border-radius: 100px;
  border: 2px solid black;
  color: black;
  outline: none;
  width: 100%;
  height: 64px;
  font-weight: bold;
  font-size: 24px;
  font-family: "Kulim Park", serif;
transition: all 90ms ease-in-out;
  background: transparent;
  &:hover {
    border: 2px solid black;
    background: white;
    transition: all 90ms ease-in-out;
  }
`

const StyledButton = styled.button`
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding: 0px 24px 0px 24px;
    overflow: hidden;
    align-content: center;
    flex-wrap: nowrap;
    gap: 0;
    border-radius: 144px;
    border: 3px solid #222;
    font-size: 32px;
    font-weight: bold;
    font-family: "Kulim Park", serif;
    background: var(--color-primary);
    color: white;
    transition: all 90ms ease-in-out;
    &:hover {
        background: white;
        color: black;
        transition: all 90ms ease-in-out;
    }
`

const Container = styled(motion.div)`
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 8px;
    overflow: visible;
    align-items: center;
    flexDirection: column;
    gap: 24px;
    font-family: "Kulim Park", serif;

    & > input {
        color: blue;
        width: 100% !important;
    }
`

const Input = styled.input`
    width: 100%;
    margin-bottom: 16px;
    height: 40px;
    font-family: "Kulim Park", serif;
    font-size: 18px;
    padding: 8px;
    border-radius: 8px;
    transition: all 90ms ease-in-out;
    &: focus {
        outline: 2px solid black;
        outline-offset: 2px;
        transition: all 90ms ease-in-out;
    }
`

const Select = styled.select`
    width: 100%;
    margin-bottom: 16px;
    height: 40px;
    font-family: "Kulim Park", serif;
    font-size: 18px;
    padding: 8px;
     border-radius: 8px;
`

const CustomForm = styled.form`
    width: 100%;
    margin-bottom: 16px;
    overflow: visible;
    max-width: 720px;
    margin: 0 auto;
`

const Textarea = styled.textarea`
    width: 100%;
    margin-bottom: 16px;
    height: 80px;
    font-family: "Kulim Park", serif;
    font-size: 18px;
    padding: 8px;
     border-radius: 8px;
    transition: all 90ms ease-in-out;
    &: focus {
        outline: 2px solid black;
        outline-offset: 2px;
        transition: all 90ms ease-in-out;
    }
`

const Label = styled.label`
    width: 100%;
    margin-bottom: 8px;
    font-size: 32px;
    display: block;
`

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    flexDirection: "column",
}
// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style

export default function Form(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <Container initial={fadeIn.hidden} animate={fadeIn.visible}  custom={1}>
            <CustomForm action="https://submit-form.com/XDJEVS0f">
                <Label htmlFor="name">Name*</Label>
                <Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    required
                />
                <Label htmlFor="email">Email*</Label>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    required
                />
                <Label htmlFor="phone">Phone number(Optional)</Label>
                <Input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="123-456-789"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    required=""
                />
                <Label htmlFor="phone">Will you be attending?*</Label>
                <Select name="attending" id="attending">
                    <option defaultValue="true" data-isdefault="true" value>
                        Select
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Select>
                <Label htmlFor="phone">Will you be camping?*</Label>
                <Select name="camping" id="camping" required="">
                    <option defaultValue="true" data-isdefault="true" value="">
                        Select
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Select>
                <Label htmlFor="message">Message</Label>
                <Textarea
                    id="message"
                    name="message"
                    placeholder="Message"
                    required="Optional"
                ></Textarea>
                <StyledButton type="submit">Send</StyledButton>
            </CustomForm>
        </Container>
    )
}
