import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import Form from "../components/form"
import { useInView, motion } from "framer-motion"
import { fadeIn, draw, fadeInUp } from "../components/animation"

const SectionHeader = styled.div`
  display: flex;
  font-size: 72px;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #2b1612;

  @media (max-width: 810px) {
    font-size: 48px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`

const Contact = () => (
  <Layout id="contact">
    <motion.h1 style={{fontSize: "72px", textAlign: "center", margin: "80px 0"}} initial={{ opacity: 0, y: 32 }}
    whileInView={{ opacity: 1, y: 0, transition: {delay: 0.1} }}
    animate={{ opacity: 0, y: 32 }}>RSVP</motion.h1>
    <Form initial={{opacity: 0}}/>
  </Layout>
)

export const Head = () => <Seo title="Page two" />

export default Contact
